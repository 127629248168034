// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-2257-js": () => import("./../../../src/pages/2257.js" /* webpackChunkName: "component---src-pages-2257-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-friends-js": () => import("./../../../src/pages/friends.js" /* webpackChunkName: "component---src-pages-friends-js" */),
  "component---src-pages-how-to-js": () => import("./../../../src/pages/how-to.js" /* webpackChunkName: "component---src-pages-how-to-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-invitation-js": () => import("./../../../src/pages/invitation.js" /* webpackChunkName: "component---src-pages-invitation-js" */),
  "component---src-pages-join-application-js": () => import("./../../../src/pages/join-application.js" /* webpackChunkName: "component---src-pages-join-application-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-process-password-reset-js": () => import("./../../../src/pages/process-password-reset.js" /* webpackChunkName: "component---src-pages-process-password-reset-js" */),
  "component---src-pages-reset-password-js": () => import("./../../../src/pages/reset-password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-yoti-failure-js": () => import("./../../../src/pages/yoti-failure.js" /* webpackChunkName: "component---src-pages-yoti-failure-js" */),
  "component---src-templates-modelpage-js": () => import("./../../../src/templates/modelpage.js" /* webpackChunkName: "component---src-templates-modelpage-js" */),
  "component---src-templates-modelsriver-js": () => import("./../../../src/templates/modelsriver.js" /* webpackChunkName: "component---src-templates-modelsriver-js" */),
  "component---src-templates-profile-js": () => import("./../../../src/templates/profile.js" /* webpackChunkName: "component---src-templates-profile-js" */),
  "component---src-templates-single-js": () => import("./../../../src/templates/single.js" /* webpackChunkName: "component---src-templates-single-js" */)
}

